import React, { useEffect, useState } from 'react';
import { MfePropsType } from '../src/types/mfeProps';
import createIdHelper from '../src/utils/createIdHelper';
import skeletonHtml from '../src/skeleton/index.html';

const RootComponent = import('./root.component');

const RootLoader = (props: MfePropsType) => {
  const [Component, setComponent] = useState<any>(React.Fragment);

  useEffect(() => {
    RootComponent.then((response) =>
      setComponent(() => response?.default as any)
    );
  }, []);

  return (
    <div
      style={{ position: 'relative' }}
      data-testid={createIdHelper('root-container')}
    >
      <div
        style={{
          position: 'absolute',
          width: '100vw',
          height: '100vh',
          zIndex: -1
        }}
        data-testid={createIdHelper('skeleton-container')}
        dangerouslySetInnerHTML={{ __html: skeletonHtml }}
      />
      <Component {...props} />
    </div>
  );
};

export default RootLoader;
